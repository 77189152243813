// import { Carousel, Collapse, Dropdown } from 'bootstrap';
// import Lightbox from 'bs5-lightbox';

import Carousel from 'bootstrap/js/dist/carousel';
import Collapse from 'bootstrap/js/dist/collapse';
import Dropdown from 'bootstrap/js/dist/dropdown';
import Modal from 'bootstrap/js/dist/modal';

window.bootstrap = {Carousel: Carousel, Modal: Modal};

const carouselSmall = document.getElementById('carousel-small');

createCarouselElements(carouselSmall);

const navbarToggler = Collapse.getOrCreateInstance('#navbarNav', { toggle: false });
const dropDownToggler = Dropdown.getOrCreateInstance('#services-dropdown');


const mainTitle = document.querySelector('.main-title');
const mainTitleContainer = document.querySelector('.main-title-container');
const fontRatio = 0.05;

if (mainTitle) {
    mainTitle.style.fontSize = mainTitleContainer.clientWidth * fontRatio + "px";
}

window.addEventListener('resize', () => {
    if (mainTitle) {
        mainTitle.style.fontSize = mainTitleContainer.clientWidth * fontRatio + "px";
    }
})

window.addEventListener('beforeunload', () => {
    navbarToggler.hide();
})

document.querySelectorAll('body >*:not(header)').forEach(element => {
    element.addEventListener('click', () => {
        navbarToggler.hide();
    })
})
document.querySelector('#contact-button').addEventListener('click', () => {
    navbarToggler.hide();
})


document.getElementById('navbarNav').addEventListener('show.bs.collapse', () => {
    let page = window.location.pathname.replace('/', '');
    let servicePages = ['kadernictvo', 'predlzovanie-vlasov', 'kozmetika', 'licenie', 'makeup']
    if (servicePages.includes(page)) {
        dropDownToggler.show();
    }
})

document.querySelectorAll('.dropdown-item').forEach(element => {
    element.addEventListener('click', () => {
        dropDownToggler.hide();
    })
})

function createCarouselElements(carouselElement) {
    if (carouselElement) {
        let carouselItems = carouselElement.querySelectorAll('.carousel .carousel-item');

        carouselItems.forEach(element => {
            const minPerSlide = 4;
            let next = element.nextElementSibling;
            for (var i = 1; i < minPerSlide; i++) {
                if (!next) {
                    // wrap carousel by using first child
                    next = carouselItems[0];
                }
                let cloneChild = next.cloneNode(true);
                element.appendChild(cloneChild.children[0]);
                next = next.nextElementSibling;
            }
        });

        const lightbox = document.createElement('script');
        lightbox.src = 'js/index.bundle.min.js';
        document.body.appendChild(lightbox);
       
    }
}

const map = document.getElementById('map');
let mapDone = false;
window.addEventListener('load', () => {
    if (!mapDone) {
        mapDone = true;
        
        getReviews();

        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    mapListener();
                    observer.disconnect();
                }
            })
        })

        observer.observe(map);
    }
});

function mapListener() {
    // const frame = document.createElement('iframe');
    // frame.src = map.getAttribute('data-src');
    // frame.width = '300';
    // frame.height = '300';
    // frame.title = 'map';
    // frame.referrerPolicy = 'no-referrer-when-downgrade'
    // map.appendChild(frame);

    const maplink = document.createElement('a');
    maplink.href = 'https://www.google.com/maps/place/Glamora+Beauty+Salon/@48.7189041,21.2383891,15z/data=!4m6!3m5!1s0x473ee160be80e63d:0x88dcb3bdf8ccccb8!8m2!3d48.7189041!4d21.2383891!16s%2Fg%2F11rxhtp8f9?entry=ttu';
    maplink.target = '_blank';
    maplink.referrerPolicy = 'no-referrer';
    maplink.ariaLabel = 'Map';

    const img = document.createElement('img');
    img.alt = 'Map';
    img.src = 'https://maps.googleapis.com/maps/api/staticmap?center=Glamora+Beauty+Salon+Kosice&zoom=18&size=300x300&key=AIzaSyCDp3hDHAqtiGU3FPlwOTWfVBKBBc4_TTg';
    
    maplink.appendChild(img);
    map.appendChild(maplink);
}

async function getReviews() {
    reviewsContainer = document.getElementById('flush-reviews');
    if (reviewsContainer) {
        try {
            const response = await fetch('/reviews');
            if (response.ok) {
                const data = await response.json();
                const reviews = data.reviews;
                let review = reviews[0];
                reviews.forEach(review => {
                    let div = document.createElement('div');
                    div.classList.add('mt-5');
                    let authorName = review.authorAttribution.displayName;
                    let photoSrc = review.authorAttribution.photoUri;
                    let rating = review.rating;
                    let published = review.relativePublishTimeDescription;
                    let text = review.originalText.text;
                    div.innerHTML =
                        `<div>
                <img class="d-inline" src=${photoSrc} width="32" height="32" alt="">
                <p class="h3 d-inline align-bottom ps-3">${authorName}</p>
                </div>
                <div class="my-3">
                <span>★</span>                
                <span>★</span>                
                <span>★</span>                
                <span>★</span>                
                <span>★</span>                
                </div>
                <p>${text}</p>
                `;
                    let stars = div.querySelectorAll('span');
                    stars.forEach((star, i) => {
                        if (i < rating) {
                            star.style.color = 'orange';
                        }
                    })
                    reviewsContainer.appendChild(div);
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
}